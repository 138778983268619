export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  VIN?: string;
  unitNum?: string;
  idleReductionTime: number;
  engineOnTime: number;
  ignitionOnTime: number;
  CO2Savings?: number;
  engineOnTimePark: number;
  engineOnTimeDriving: number;
  inParkTime: number;
  status: string;
  stealthBatteryVolts?: number;
  OEMBatteryVoltage?: number;
  SPGraph?: Date[];
  dates?: string[];
  SPVoltageGraph?: (number | null)[];
  OEMVoltageGraph?: (number | null)[];
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
