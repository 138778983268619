import { ReactElement, ReactNode } from "react";

export const ROUTE_LOADING = "ROUTE_LOADING";
export const ROUTE_FAILURE = "ROUTE_FAILURE";
export const ROUTE_SUCCESS = "ROUTE_SUCCESS";

export type VehicleRoute = {
  id: string;
  route: Array<Coordinate>;
  timestamp: string;
  StealthBatteryVolts: number;
  SystemTemperature: number;
};

export type Coordinate = {
  lat: number;
  lng: number;
};

export interface RouteLoading {
  type: typeof ROUTE_LOADING;
}

export interface RouteFailure {
  type: typeof ROUTE_FAILURE;
}

export interface RouteSuccess {
  type: typeof ROUTE_SUCCESS;
  payload: Array<VehicleRoute>;
}

export type RouteDispatchTypes = RouteLoading | RouteFailure | RouteSuccess;
