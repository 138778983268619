import { combineReducers } from "redux";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";
import routeReducer from "./routeReducer";
import popupReducer from "./popupReducer";
import labeledVehiclesReducer from "./labeledVehiclesReducer";
import vehicleListReducer from "./vehicleListReducer";

const rootReducer = combineReducers({
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  vehicleRoutes: routeReducer,
  popupView: popupReducer,
  labeledVehicles: labeledVehiclesReducer,
  vehicleList: vehicleListReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
