import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../theme/ThemeContext";
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import logoLightmode from "../../logos/logo-lightmode.svg";
import logoDarkmode from "../../logos/logo-darkmode.png";
import { NavLink } from "react-router-dom";
import styles from "./AppHeader.module.scss";
import CIcon from "@coreui/icons-react";
import {
  cilLocationPin,
  cilSettings,
  cilSpeedometer,
  cilHamburgerMenu,
} from "@coreui/icons";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButton,
} from "@coreui/react";

const routes = [
  {
    link: "/",
    name: "Map",
    icon: cilLocationPin,
  },
  {
    link: "/metrics/",
    name: "Metrics",
    icon: cilSpeedometer,
  },
  {
    link: "/settings/",
    name: "Settings",
    icon: cilSettings,
  },
];

export default function AppHeader() {
  const { darkmode } = useContext(ThemeContext);
  const { logout, user, isAuthenticated } = useAuth0();
  const [desktopDisplay, setDesktopDisplay] = useState(true);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // Determine if desktop or mobile size
  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width <= 1080) {
      setDesktopDisplay(false);
    } else {
      setDesktopDisplay(true);
    }
  };

  return (
    <div>
      <header
        className={classNames(
          "c-header c-header-light c-header-fixed",
          darkmode ? styles.headerStyleDark : styles.headerStyleLight
        )}
        data-testid="app-header"
        style={{ display: "flex" }}
      >
        <nav className={classNames(styles.nav, "ml-sm-3", "mr-auto")}>
          <ul className="mr-auto c-header-nav">
            {/* Client company icon */}
            <li>
              <NavLink to="/">
                {darkmode ? (
                  <img
                    style={{
                      marginRight: "10px",
                      bottom: "5px",
                      marginLeft: "10px",
                      height: "40px",
                    }}
                    src={logoDarkmode}
                  />
                ) : (
                  <img src={logoLightmode} />
                )}
              </NavLink>
            </li>
            {/* The route icon + route name */}
            {routes.map((route) => (
              <li
                className="px-sm-3"
                key={route.name}
                style={{
                  marginTop: "10px",
                  display: desktopDisplay ? "" : "none",
                }}
              >
                <NavLink
                  style={{ color: darkmode ? "#27a088" : "#797d83" }}
                  to={route.link}
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  <CIcon icon={route.icon} size="sm" className="mr-sm-2" />{" "}
                  {route.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="mr-sm-3 c-header-nav" style={{ marginLeft: "auto" }}>
          <CDropdown alignment={"end"}>
            <CDropdownToggle
              color={darkmode ? "dark" : "light"}
              caret={false}
              style={{
                flexShrink: 0,
                flexWrap: "nowrap",
                display: "flex",
                alignItems: "center",
                border: "none",
                boxShadow: "none",
                backgroundColor: darkmode ? "#252a2f" : "white",
                padding: 0,
                marginRight: "30px",
                borderColor: "transparent",
                height: "40px",
                color: darkmode ? "white" : "black",
              }}
            >
              {/* User profile information from auth0 */}
              {user !== undefined && isAuthenticated && (
                <>
                  <div style={{ display: desktopDisplay ? "flex" : "none" }}>
                    <div>{user.name ? user.name : "User Name"}</div>
                    <div
                      className="c-avatar"
                      style={{
                        margin: "-5px 0.5rem",
                      }}
                    >
                      {user.picture && (
                        <img
                          className={styles.imgCircle}
                          alt={user.name}
                          src={user.picture}
                        />
                      )}
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <svg
                        width="9"
                        height="4"
                        viewBox="0 0 9 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.620372 0.174604C0.800083 -0.0350587 1.11573 -0.0593393 1.3254 0.120372L4.5 2.84146L7.6746 0.120372C7.88427 -0.0593393 8.19992 -0.0350587 8.37963 0.174604C8.55934 0.384267 8.53506 0.699917 8.3254 0.879628L4.88566 3.82798L4.88562 3.82801C4.66371 4.01818 4.33629 4.01818 4.11438 3.82801L4.11434 3.82798L0.674604 0.879628C0.464941 0.699917 0.440661 0.384267 0.620372 0.174604Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* Hamburger icon if mobile */}
                  <div style={{ display: desktopDisplay ? "none" : "" }}>
                    <CIcon
                      icon={cilHamburgerMenu}
                      size="lg"
                      className="mr-sm-2"
                    ></CIcon>
                  </div>
                </>
              )}
            </CDropdownToggle>

            {/* Hamburger dropdown routes + logout, if mobile */}
            <CDropdownMenu className="pt-0">
              {routes.map((route) => (
                <CDropdownItem
                  className={styles.tabStyle}
                  style={{
                    display: desktopDisplay ? "none" : "",
                    padding: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "25%",
                  }}
                >
                  <NavLink to={route.link} className={styles.hamburgerStyle}>
                    <CIcon icon={route.icon} size="sm" /> {route.name}
                  </NavLink>
                </CDropdownItem>
              ))}
              <CDropdownItem
                className="text-center"
                style={{
                  color: darkmode ? "white" : "black",
                  backgroundColor: "white",
                }}
              >
                <CButton
                  color={darkmode ? "dark" : "light"}
                  variant="ghost"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className={
                    darkmode ? styles.btnGhostDark : styles.btnGhostLight
                  }
                >
                  Log Out
                </CButton>
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </header>
      <div></div>
    </div>
  );
}
